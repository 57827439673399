import { useState, useLayoutEffect } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useLayoutEffect(() => {
    let timerId = null;

    const handleResize = () => {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => {
        setWindowDimensions(getWindowDimensions());
      }, 200); // Adjust the debounce delay as needed
    };

    window.addEventListener('resize', handleResize, { passive: true });

    return () => {
      window.removeEventListener('resize', handleResize);
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, []);

  return windowDimensions;
}


// import { useState, useEffect } from 'react';
// import { debounce } from 'lodash';
//
// function getWindowDimensions() {
//   const { innerWidth: width, innerHeight: height } = window;
//   return {
//     width,
//     height
//   };
// }
//
// export default function useWindowDimensions() {
//   const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
//
//   useEffect(() => {
//     function handleResize() {
//       setWindowDimensions(getWindowDimensions());
//     }
//         const debouncedUpdateSize = debounce(handleResize, 200);
//
//     window.addEventListener('resize', debouncedUpdateSize);
//     return () => window.removeEventListener('resize', debouncedUpdateSize);
//   }, []);
//
//   return windowDimensions;
// }