import React, {useEffect} from "react";
import {Redirect, useHistory, useLocation, withRouter} from "react-router";
import "./App.css";
import Routes from "./Routes";
import {Auth} from "aws-amplify";
import QNavBar from "./containers/Navigation/QNavBar";
import {useTheme} from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import {AppContext} from "./libs/appContextLib";
import axios from "axios";
import {config} from "./config";
import UnauthenticatedFooter from "./containers/UnauthenticatedFooter";
import TrialReminderSnackbar from "./containers/TrialReminder/TrialReminderSnackbar";
import LogRocket from 'logrocket';
import sortBy from 'lodash/sortBy';
import {Hub} from "@aws-amplify/core";
import {get} from "lodash";
import Box from "@mui/material/Box";
import QPilotChatbot from "./containers/QPilotChatbot";
import TagManager from "react-gtm-module";
import HomepageHelmet from "./containers/Home/HomepageHelmet";
import CookieConsent from "react-cookie-consent";
import useWindowDimensions from "./components/WindowDimensions";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import {MdInfo} from "react-icons/md";
import {Typography} from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import {format, max, parseJSON} from "date-fns";
import Link from "@mui/material/Link";
import {getCalApi} from "@calcom/embed-react";
import "./dsg-styles-overrides.css";
import {useQueryClient} from "@tanstack/react-query";
import apiClient from "./queries/apiClient";
import {useFetchDataStatus} from "./queries/useFetchDataStatus";


const stripePromise = loadStripe(config.STRIPE_KEY)


const App = (props) => {


    const {width} = useWindowDimensions()
    const isMobile = width <= 494

    const queryClient = useQueryClient()

    useEffect(() => {
        (async function () {
            const cal = await getCalApi();
            cal("ui", {
                preload: true,
                theme: "light",
                styles: {
                    // body: { backgroundColor: "#272931" },
                    branding: {brandColor: "#272931"},
                },
            });
        })();
    }, []);

    let location = useLocation()

    const [drawerWidth, setDrawerWidth] = React.useState(0)
    const [qPilotDrawerWidth, setQPilotDrawerWidth] = React.useState(32)
    const [errorRequest, setErrorRequest] = React.useState(false);
    const [isAuthenticating, setIsAuthenticating] = React.useState(true);
    const [isAuthenticated, setIsAuthenticated] = React.useState(false);
    const [mobileMarketingMenuOpen, setMobileMarketingMenuOpen] = React.useState(false)
    const [currUser, setCurrUser] = React.useState({});
    const [showQPilotChat, setShowQPilotChat] = React.useState(false)


    const [open, setOpen] = React.useState(localStorage.getItem("qNavBarOpen") === 'true')


    const handleErrorRequest = () => {
        setErrorRequest(!errorRequest);
    };

    const pathName = location.pathname.slice(0, location.pathname.indexOf("/", 1) > -1 ? location.pathname.indexOf("/", 1) : undefined)

    const handleShowQPilotChatChange = (e) => {
        showQPilotChat ? setShowQPilotChat(false) : setShowQPilotChat(true)
        showQPilotChat ? setQPilotDrawerWidth(32) : setQPilotDrawerWidth(390)
    }


    const [dataStatus, setDataStatus] = React.useState([])
    const [processingStatus, setProcessingStatus] = React.useState([])
    const [fiscalQuarters, setFiscalQuarters] = React.useState([])
    const [userStatus, setUserStatus] = React.useState([])
    const [subscriptionStatus, setSubscriptionStatus] = React.useState([])
    const [dataSetupComplete, setDataSetupComplete] = React.useState(true)
    const [forecastBucketMap, setForecastBucketMap] = React.useState({})
    const [corporateCurrency, setCorporateCurrency] = React.useState("USD")
    const [corporateTimezone, setCorporateTimezone] = React.useState("")
    const [activeCrm, setActiveCrm] = React.useState("")
    const [activeAccountingPlatform, setActiveAccountingPlatform] = React.useState("")
    const [showTrialTeasers, setShowTrialTeasers] = React.useState(false)
    const [trialStatus, setTrialStatus] = React.useState({status: "", trialingUntil: null})

    useEffect(() => {

        let isApiSubscribed = true;
        if (isApiSubscribed) {
            let abortController = new AbortController()
            let signal = abortController.signal
            onLoad(signal)
            return () => {
                abortController.abort()
                isApiSubscribed = false;
            }
        }


    }, []);

    useEffect(() => {
        open !== undefined && localStorage.setItem('qNavBarOpen', `${open}`)
    }, [open]);

    useEffect(() => {
        if (isAuthenticated) {
            activeCrm && checkSetupStatus(activeCrm);
        }
    }, [dataStatus, fiscalQuarters
        , activeCrm
        // , forecastBucketMap
    ]);


    const setupLocation =
        {
            data_source_crm_initialValue: "/admin/company/create/0",
            data_source_crm_initial_data_last_capturedValue: "/admin/company/create/0",
            data_source_mktg_initialValue: "/admin/company/create/1",
            data_source_mktg_initial_data_last_capturedValue: "/admin/company/create/1",
            data_config_define_arrComplete: "/admin/company/configure-data/0",
            data_config_opp_typesComplete: "/admin/company/configure-data/1",
            data_config_opp_stagesComplete: "/admin/company/configure-data/2",
            data_config_opp_rolesComplete: "/admin/company/configure-data/3",
            data_config_program_typesComplete: "/admin/company/configure-data/4",
            // data_config_custom_attsComplete: "/admin/company/configure-data/5",
            data_config_prof_typesComplete: "/admin/company/configure-data/5",
        }


    const [trialAlertOpen, setTrialAlertOpen] = React.useState(false)

    const handleTrialAlertClose = e => {
        setTrialAlertOpen(!trialAlertOpen)
    }

    useEffect(() => {
        if (isAuthenticated) {
            const now = new Date()
            const nowUTC = now.getTime() / 1000
            // const currentTrialEnd = parseInt(get(subscriptionStatus, 'stripe_subscription.stripe_trial_end', undefined))
            const currentTrialEnd = parseInt(get(dataStatus, 'trial_end_date', undefined))
            const currentTrialEndDate = new Date(currentTrialEnd)
            if (currentTrialEndDate > nowUTC) {
                setTrialAlertOpen(true)
            }
        }
    }, [subscriptionStatus])


    useEffect(() => {
        Hub.listen("auth", ({payload: {event, data}}) => {
            switch (event) {
                case "signIn":
                    onLoad().then(result => {
                    })
                    break;
                case "cognitoHostedUI":
                    break;
                case "signOut":
                    break;
                case "signIn_failure":
                case "cognitoHostedUI_failure":
                    console.log("Sign in failure", data);
                    setErrorRequest(data)
                    break;
            }
        });
    }, []);


    const checkSetupStatus = (activeCrmLocal) => {

        const activeCrmToCheck = activeCrmLocal ? activeCrmLocal : activeCrm
        const data_source_sfdc_initialValue = get(dataStatus, 'processing_status.status_data_source.sfdc.completed', null)
        const data_source_sfdc_initial_data_last_capturedValue = get(dataStatus, 'processing_status.status_data_ingest.sfdc_initial_data.completed', null)
        const data_source_hscrm_initialValue = get(dataStatus, 'processing_status.status_data_source.hscrm.completed', null)
        const data_source_hscrm_initial_data_last_capturedValue = get(dataStatus, 'processing_status.status_data_ingest.hscrm_initial_data.completed', null)
        let dataSourceCrmInitialValue = activeCrmToCheck === "hscrm" ? data_source_hscrm_initialValue : data_source_sfdc_initialValue
        let dataSourceCrmLastCaptured = activeCrmToCheck === "hscrm" ? data_source_hscrm_initial_data_last_capturedValue : data_source_sfdc_initial_data_last_capturedValue

        const data_source_mktg_initialValue = get(dataStatus, 'processing_status.status_data_source.mktg_initial_data.completed', null)
        const data_source_mktg_initial_data_last_capturedValue = get(dataStatus, 'processing_status.status_data_ingest.mktg_initial_data.completed', null)
        const data_config_define_arrComplete = get(dataStatus, 'processing_status.status_data_config.define_arr.complete', null)
        const data_config_opp_typesComplete = get(dataStatus, 'processing_status.status_data_config.opp_types.complete', null)
        const data_config_opp_stagesComplete = get(dataStatus, 'processing_status.status_data_config.opp_stages.complete', null)
        const data_config_opp_rolesComplete = get(dataStatus, 'processing_status.status_data_config.opp_roles.complete', null)
        const data_config_program_typesComplete = get(dataStatus, 'processing_status.status_data_config.program_types.complete', null)

        const data_config_prof_typesComplete = get(dataStatus, 'processing_status.status_data_config.prof_types.complete', null)
        if (dataStatus
            // && Object.keys(dataStatus).length > 0
        ) {
            if (dataSourceCrmInitialValue === null ||
                dataSourceCrmLastCaptured === null ||
                // data_source_mktg_initialValue === null ||
                // data_source_mktg_initial_data_last_capturedValue === null ||
                data_config_define_arrComplete === null ||
                data_config_opp_typesComplete === null ||
                data_config_opp_stagesComplete === null ||
                data_config_opp_rolesComplete === null ||
                data_config_program_typesComplete === null ||
                // data_config_custom_attsComplete === null ||
                data_config_prof_typesComplete === null
            ) {

                setDataSetupComplete(false)
            } else {
                setDataSetupComplete(true)
            }
            if (
                (!location.search || location.search.length === 0) &&
                !isAuthenticating &&
                isAuthenticated
                && location.pathname.slice(0, 6) !== "/admin"
                && location.pathname.slice(0, 8) !== "/company"
                && location.pathname !== "/settings"
                && location.pathname !== "/contact-us"
                && location.pathname.slice(0, 4) !== "/faq"
                && location.pathname !== "/service-terms"
            ) {
                if (dataStatusNew && Object.keys(dataStatusNew).length > 0 && currUser.role === 'admin_user' && activeCrm) {
                    if (dataSourceCrmInitialValue === null) {
                        history.push(setupLocation['data_source_crm_initialValue'])
                    } else if (dataSourceCrmLastCaptured === null) {
                        history.push(setupLocation['data_source_crm_initial_data_last_capturedValue'])
                    } else if (data_config_define_arrComplete === null) {
                        history.push(setupLocation['data_config_define_arrComplete'])
                    }
                        // else if (data_config_opp_typesComplete === null) {
                        //     history.push(setupLocation['data_config_opp_typesComplete'])
                    // }
                    else if (data_config_opp_stagesComplete === null) {
                        history.push(setupLocation['data_config_opp_stagesComplete'])
                    } else if (data_config_opp_rolesComplete === null) {
                        history.push(setupLocation['data_config_opp_rolesComplete'])
                    } else if (data_config_program_typesComplete === null) {
                        history.push(setupLocation['data_config_program_typesComplete'])
                    } else if (data_config_prof_typesComplete === null) {
                        history.push(setupLocation['data_config_prof_typesComplete'])
                    }
                }
            }
        }
    }


    const {
        status: dataStatusFetchingStatus,
        data: dataStatusNew,
        error: dataStatusError,
        isFetching: isFetchingDataStatus,
    } = useFetchDataStatus()


    // prefetch scenarios
    // const nextPageQueryKey = [
    //                   'oppViewDataPaginated',
    //                   {
    //                       qSortOrder: JSON.stringify(qSortOrder),
    //                       serverQuery: JSON.stringify(serverQuery),
    //                       qCurrentPage: nextPage,
    //                       qSearchText,
    //                       qRowsPerPage,
    //                       campaignFilters: memoizedCampaignFilters,
    //                       activeDateOptions: memoizedActiveDateOptions,
    //                       dealTypeSelection: memoizedDealTypeSelection,
    //                       typeToggleChecked: props.typeToggleChecked,
    //                       segmentFilters: memoizedSegmentFilters,
    //                       selectedCustomQuery: memoizedSelectedCustomQuery,
    //                       contactTreatment: props.contactTreatment
    //                   },
    //               ];
    //
    //               // console.log(`Prefetching page ${nextPage} with queryKey:`, nextPageQueryKey);

    const prefetchQueries = () => {
        queryClient.prefetchQuery({
            queryKey: [`${currUser.customerId}_existing-scenarios`],
            queryFn: async () => {
                const response = await apiClient.get(`${config.REACT_APP_BACKEND_HOST}/scenarios`, {});
                return response.data
            },
            staleTime: 1000 * 60 * 60 * 36, // or a high value if you want some automatic refetch
            cacheTime: 1000 * 60 * 60 * 36, // 36 hour -- or until invalidated
            enabled: !!currUser && !!currUser.customerId
        });
    }


    useEffect(() => {
        currUser && Object.keys(currUser).length > 0 && prefetchQueries()
    }, [currUser])


    useEffect(() => {
        dataStatusNew && Object.keys(dataStatusNew).length > 0 && setDataStatus(dataStatusNew.dataStatus)
    }, [dataStatusNew])


    const getUserPreferenceDateRangeOptions = () => {
        const dateRangeAsDefault = window.localStorage.getItem(`${currUser.customerId}_dateRangeOptions`)

        return dateRangeAsDefault ? JSON.parse(dateRangeAsDefault) : undefined
    }

    const userPreferenceDateRangeOptions = getUserPreferenceDateRangeOptions()

    const onLoad = async (signal) => {
        try {
            await Promise.all([
                await Auth.currentSession(),
                await Auth.currentAuthenticatedUser()
                    .then(authenticatedUser => {
                            const emailVerifiedStatus = get(authenticatedUser, 'attributes.email_verified', false)
                            let currentUserToSet = {
                                authToken: authenticatedUser.signInUserSession.accessToken.jwtToken,
                                id: authenticatedUser.attributes.sub,
                                role: authenticatedUser.attributes["custom:currentUserRole"],
                                firstName: authenticatedUser.attributes["given_name"],
                                lastName: authenticatedUser.attributes["family_name"],
                                email: authenticatedUser.attributes["email"],
                                emailVerified: emailVerifiedStatus ? emailVerifiedStatus : false,
                                phone: authenticatedUser.attributes["phone"] ? authenticatedUser.attributes["phone"] : null,
                                companyName: authenticatedUser.attributes["custom:companyName"],
                                customerId: authenticatedUser.attributes["custom:qflowCustomerId"],
                                sfdcUrl: authenticatedUser.attributes["custom:companySalesforceUrl"] ? authenticatedUser.attributes["custom:companySalesforceUrl"] : 'https://login.salesforce.com',
                                authPref: authenticatedUser.attributes["custom:auth_preference"] ? authenticatedUser.attributes["custom:auth_preference"] : "built-in",
                            }
                            setCurrUser(currentUserToSet)

                            // Don't let regular users access test instance.
                            if (process.env.REACT_APP_STAGE === 'dev'
                                && authenticatedUser.attributes["custom:qflowCustomerId"] !== "2a2b4a38-4635-47d4-9a36-3736a1d051c5"
                                && !(currentUserToSet.email.indexOf('greenwald') > -1
                                    || currentUserToSet.email.indexOf('qflow') > -1)) {
                                setIsAuthenticated(false);
                                queryClient.invalidateQueries()
                                Auth.signOut()


                            } else if (emailVerifiedStatus) {
                                // alert("App: onload: email is verified")
                                setIsAuthenticated(true);
                                // alert("App:  onload: setIsAuthenticated")
                                setDrawerWidth(240)
                                axios.get(`${config.REACT_APP_BACKEND_HOST}/configure/data/status`, {
                                    headers: {Authorization: currentUserToSet.authToken},
                                    signal: signal,
                                    params: {}
                                }).then(response => {
                                    const currentUserToSetWithCrmUrl = {
                                        ...currentUserToSet,
                                        sfdcUrl: response.data.dataStatus.sfdcUrl
                                    }

                                    if (get(response.data, "subscriptionStatus.exclude_data_gen") && authenticatedUser.attributes["custom:qflowCustomerId"] !== "2a2b4a38-4635-47d4-9a36-3736a1d051c5") {
                                        location.pathname.slice(0, 6) !== "/admin"
                                        && location.pathname.slice(0, 8) !== "/company"
                                        && location.pathname !== "/settings"
                                        && location.pathname !== "/contact-us"
                                        && location.pathname.slice(0, 4) !== "/faq"
                                        && location.pathname !== "/service-terms" && location.pathname.slice(0, 14) !== "/admin/billing"
                                        && history.push('/trial-ended')
                                    }

                                    if (get(response.data, "subscriptionStatus.show_trial_teasers")) {
                                        // process.env.REACT_APP_STAGE !== "dev"  &&
                                        setShowTrialTeasers(true)
                                    }

                                    const trialEndDateToSet = get(response.data, "subscriptionStatus.trial_end_date", null)
                                    const startSubscriptionStart = get(response.data, "subscriptionStatus.stripe_subscription.stripe_subscription_created", null)
                                    if (trialEndDateToSet) {
                                        const didUpgrade = startSubscriptionStart?.length > 0
                                        setTrialStatus({
                                            status: didUpgrade || get(response.data, "subscriptionStatus.show_trial_teasers") ? "trial_ended" :
                                                !get(response.data, "subscriptionStatus.trial_end_date", null) ? "not_trialing" : "trialing",
                                            trialingUntil: trialEndDateToSet
                                        })
                                    }

                                    setCurrUser(currentUserToSetWithCrmUrl)
                                    // checkSetupStatus(response.data.crm)
                                    setActiveCrm(response.data.crm)
                                    setActiveAccountingPlatform(response.data["accounting_platform"])
                                    response.data['functional_currency'] && setCorporateCurrency(response.data['functional_currency'])
                                    setCorporateTimezone(response.data['corporate_tz'])
                                    setDataStatus(response.data.dataStatus)
                                    setProcessingStatus(response.data.dataStatus['processing_status_new'])

                                    let fiscalQuartersToSet = response.data['fiscalQuarters']
                                    let fiscalQuartersSorted = sortBy(fiscalQuartersToSet, o => o['StartDate'])
                                    localStorage.setItem(`${currentUserToSet.customerId}-fiscalQuartersLocal`, JSON.stringify(fiscalQuartersSorted))
                                    setFiscalQuarters(fiscalQuartersSorted)
                                    if (response.data['forecastBucketMap'] && response.data['forecastBucketMap']['accepted_forecast_field_label']) {
                                        const forecastBucketMapRaw = response.data['forecastBucketMap']['accepted_forecast_field_label'];

                                        const updatedForecastBucketMap = Object.fromEntries(
                                            Object.entries(forecastBucketMapRaw).map(([key, value]) => [
                                                key,
                                                value === '' ? key : value,
                                            ])
                                        );

                                        setForecastBucketMap(updatedForecastBucketMap);
                                    } else {
                                        // Handle the case where forecastBucketMap doesn't exist
                                        setForecastBucketMap({});
                                    }


                                    // response.data['forecastBucketMap'] && setForecastBucketMap(response.data['forecastBucketMap']['accepted_forecast_field_label'])
                                    response.data['forecastBucketMap'] && localStorage.setItem(`${currentUserToSet.customerId}-forecastBucketMap`, JSON.stringify(response.data['forecastBucketMap']['accepted_forecast_field_label']))
                                    setUserStatus(response.data['userStatus'])
                                    setSubscriptionStatus(response.data.subscriptionStatus)
                                })
                                    .catch(error => {
                                        if (axios.isCancel(error)) {

                                        } else {

                                            if (error.response && error.response.status && error.response.status === 403) {

                                                console.log("Session has expired.")
                                                setIsAuthenticated(false);
                                                Auth.signOut();
                                                queryClient.invalidateQueries()

                                                console.log("Access is denied - logging out")
                                            }

                                            // Error 😨
                                            console.log("onLoad error", error)
                                            if (error.response?.data?.error === "Access denied") {
                                                setIsAuthenticated(false)
                                            }
                                            if (error.response) {
                                                /*
                                                 * The request was made and the server responded with a
                                                 * status code that falls out of the range of 2xx
                                                 */
                                                console.log(error.response.data);
                                                console.log(error.response.status);
                                                console.log(error.response.headers);
                                            } else if (error.request) {
                                                /*
                                                 * The request was made but no response was received, `error.request`
                                                 * is an instance of XMLHttpRequest in the browser and an instance
                                                 * of http.ClientRequest in Node.js
                                                 */
                                                console.log("error request", error.request);
                                                handleErrorRequest();
                                            } else {
                                                // Something happened in setting up the request and triggered an Error
                                                console.log('Error', error.message);
                                                setErrorRequest(false);
                                            }
                                            //console.log(error.config);
                                        }
                                    });
                            }
                        }
                    )
            ])

        } catch (e) {
            // if (e !== 'No current user') {
            //     alert("Please log in.");
            // }
        }
        setIsAuthenticating(false);
    }


    useEffect(() => {
        console.log(" $$$$$$\\   $$$$$$\\  $$\\                                      $$\\ \n" +
            "$$  __$$\\ $$  __$$\\ $$ |                                     \\__|\n" +
            "$$ /  $$ |$$ /  \\__|$$ | $$$$$$\\  $$\\  $$\\  $$\\     $$$$$$\\  $$\\ \n" +
            "$$ |  $$ |$$$$\\     $$ |$$  __$$\\ $$ | $$ | $$ |    \\____$$\\ $$ |\n" +
            "$$ |  $$ |$$  _|    $$ |$$ /  $$ |$$ | $$ | $$ |    $$$$$$$ |$$ |\n" +
            "$$ $$\\$$ |$$ |      $$ |$$ |  $$ |$$ | $$ | $$ |   $$  __$$ |$$ |\n" +
            "\\$$$$$$ / $$ |      $$ |\\$$$$$$  |\\$$$$$\\$$$$  |$$\\\\$$$$$$$ |$$ |\n" +
            " \\___$$$\\ \\__|      \\__| \\______/  \\_____\\____/ \\__|\\_______|\\__|\n" +
            "     \\___|                                                       \n" +
            "                                                                 \n" +
            " We're hiring ML/AI experts and software engineers at qflow.ai ")
    }, [])

    let history = useHistory();

    const handleClickedLogout = async (event) => {
        apiClient.post(`${config.REACT_APP_BACKEND_HOST}/auth-change`,
            {},
            {})
            .then((response) => {
                }
            ).catch(error => {
        })

        queryClient.invalidateQueries()

        await Auth.signOut(event).then(() => {
            setIsAuthenticated(false);
            setDrawerWidth(0)
            queryClient.invalidateQueries({queryKey: [`data-status`]});
            // sessionStorage.removeItem('currentCustomerId');
            // sessionStorage.removeItem('currentCompanySalesforceUrl');
            // sessionStorage.removeItem('currentUser');
            // sessionStorage.removeItem('currUser.lastName');
            // history.push("/login");
            // setOpen(false)
            // localStorage.setItem("qNavBarOpen", !open)
            return (<Redirect to="/login"/>)
        })
    }


    const checkDataStatusToInvalidateCache = () => {

        if (dataStatus && Object.keys(dataStatus).length > 0) {
            // console.log("Queries | dataStatusNew is", dataStatus)
            const processingStatus = dataStatus['processing_status_new']
            const processingStatusesOfInterest = processingStatus.filter(filterItem => filterItem.statusEntry === "1_core_generator" || filterItem.statusEntry === "6_on_hand_predictor_preprocess" || filterItem.statusEntry === "last_streaming_update" || filterItem.statusEntry === "4_task_generator")

            // console.log("Queries | processingStatusesOfInterest are", processingStatusesOfInterest)

            const processingStatusCompletionTimes = processingStatusesOfInterest.filter(filterItem => filterItem.status.completed).map(item => parseJSON(item.status.completed))
            const maxUpdate = max(processingStatusCompletionTimes.filter(item => typeof item === "object"))

            // console.log("Queries | processingStatusCompletionTimes are", processingStatusCompletionTimes)
            // console.log("Queries | max data build is:", maxUpdate)
            // Get all queries from the query cache
            const queries = queryClient.getQueryCache().getAll();


            // Map over the queries to extract information
            const queryInfoList = queries.map((query) => {
                const queryKey = query.queryKey;
                const lastFetched = parseJSON(query.state.dataUpdatedAt);
                return {
                    queryKey,
                    lastFetched,
                };
            });

            // console.log("Queries | query info list is:", queryInfoList)
            const queriesToInvalidate = queryInfoList.filter(filterItem => {
                return filterItem.lastFetched <= maxUpdate && filterItem.lastFetched
            })


            const queryKeysToInvalidate = queriesToInvalidate && queriesToInvalidate.length > 0 && queriesToInvalidate.map(item => item.queryKey)
            console.log("Queries | Queries to invalidate are:", queriesToInvalidate)

            if (queryKeysToInvalidate && queryKeysToInvalidate.length > 0) {
                // alert("Data has been updated — clearing cache!")
                for (const queryName in queryKeysToInvalidate) {
                    queryClient.invalidateQueries({queryKey: [queryName]})
                }
            }
        }
    }

    useEffect(() => {
        checkDataStatusToInvalidateCache()
    }, [dataStatus])


    // useEffect(() => {
    //     localStorage.setItem('qNavBarOpen', `${open}`);
    // }, [open]);

    const theme = useTheme();

    // function handleDrawerOpen(e) {
    //     e.preventDefault()
    //     // console.log("theme.breakpoints.down('md')", theme.breakpoints.down('md'))
    //     setDrawerWidth(theme.breakpoints.down('md') ? 72 : 240)
    //     setOpen(true)
    //     open !== undefined && localStorage.setItem("qNavBarOpen", false)
    // }
    //
    // function handleDrawerClose(e) {
    //     setDrawerWidth(72)
    //     setOpen(false)
    //     open !== undefined && localStorage.setItem("qNavBarOpen", false)
    // }


    function handleDrawerToggle(e) {
        e.preventDefault()
        try {
            if (open === false) {
                setOpen(true)
                // setDrawerWidth(width < 900 && !isMobile ? 72 : 240) // same as theme.breakpoints.down('md')
                setDrawerWidth(width < 900 && !isMobile ? 72 : 240) // same as theme.breakpoints.down('md')
                open !== undefined && localStorage.setItem("qNavBarOpen", !open)
            } else {
                setOpen(false)
                setDrawerWidth(width < 900 && !isMobile ? 72 : 0)
                open !== undefined && localStorage.setItem("qNavBarOpen", !open)
            }
        } catch (e) {
        }
    }

    const [mobileMarketingAnchorEl, setMobileMarketingAnchorEl] = React.useState(null)

    function handleMobileMarketingDrawerToggle(e) {
        e.preventDefault()
        // setMobileMarketingMenuOpen( !mobileMarketingMenuOpen )
        if (mobileMarketingMenuOpen === false) {
            setMobileMarketingMenuOpen(true)
            setDrawerWidth(240)
            // localStorage.setItem("qNavBarOpen", !open)
            // window.dispatchEvent(new Event('resize'));
            // window.dispatchEvent(new Event('resize'));

        } else {
            setMobileMarketingMenuOpen(false)
            setDrawerWidth(72)
        }
    }


    const tagManagerArgs = {
        dataLayer: {
            userId: currUser.id,
            userProject: currUser.companyName,
        },
        dataLayerName: 'QDataLayer'
    }

    useEffect(() => {

        process.env.REACT_APP_STAGE !== "dev" && currUser && currUser.email && TagManager.dataLayer(tagManagerArgs)
        // && currUser.email.indexOf('qflow.ai') === -1

        process.env.REACT_APP_STAGE !== "dev" && currUser && currUser.email && currUser.email.indexOf('qflow.ai') === -1 && LogRocket.init('6uvm9i/qflow')

        process.env.REACT_APP_STAGE !== "dev" && currUser && currUser.email && currUser.email.indexOf('qflow.ai') === -1 &&


        LogRocket.identify(currUser.id, {
            name: currUser.firstName + " " + currUser.lastName,
            email: currUser.email,
            // Add your own custom user variables here, ie:
            subscriptionType: subscriptionStatus
        });
    }, [currUser])

    const locationPath = location.pathname;

    const [upcomingMaintenance, setUpcomingMaintenance] = React.useState()

    async function getUpcomingMaintenanceFromServiceStatusPage() {
        axios.get(`https://status.qflow.ai/api/v2/scheduled-maintenances/upcoming.json`, {}).then(response => {
            setUpcomingMaintenance(response.data)
        }).catch(error => console.log(`there was an error fetching users: ${error}`))
    }

    useEffect(() => {
        !upcomingMaintenance && getUpcomingMaintenanceFromServiceStatusPage()
    }, [])

    return (
        !isAuthenticating
        && (isAuthenticated ? dataStatus : true)
            ?
            <Elements stripe={stripePromise}>
                <Box sx={{backgroundColor: "background.default"}}>

                    {/*<CssBaseline/>*/}
                    {/*<ErrorBoundary>*/}
                    {process.env.REACT_APP_STAGE === "dev" ?
                        null
                        :
                        <div>
                            <HomepageHelmet/>
                        </div>
                    }

                    <AppContext.Provider
                        value={{
                            userPreferenceDateRangeOptions,
                            setDrawerWidth,
                            processingStatus,
                            qPilotDrawerWidth,
                            currUser,
                            isAuthenticated,
                            corporateCurrency,
                            corporateTimezone,
                            activeCrm,
                            activeAccountingPlatform,
                            dataStatus,
                            fiscalQuarters,
                            forecastBucketMap,
                            userStatus,
                            subscriptionStatus,
                            showTrialTeasers,
                            trialStatus,
                            setIsAuthenticated,
                            setDataStatus,
                            setSubscriptionStatus,
                            onLoad,
                            dataSetupComplete,
                            drawerWidth
                            // currUser.lastName,
                            // currentCompanySalesforceUrl,
                            // currentCustomerCompanyName,
                            // currentCustomerId,
                            // currentUser,
                            // currentUserRole,
                            // dataStatus,
                            // getDataStatus
                        }}>
                        {/*<DataContext.provider*/}
                        {/*    value={{dataStatus}}>*/}

                        <QNavBar isLoggedIn={isAuthenticated}
                                 handleMobileMarketingDrawerToggle={handleMobileMarketingDrawerToggle}
                                 pathName={pathName}
                                 handleClickedLogout={handleClickedLogout}
                                 drawerWidth={drawerWidth}
                                 handleDrawerToggle={handleDrawerToggle}
                            // handleDrawerOpen={handleDrawerOpen}
                            // handleDrawerClose={handleDrawerClose}
                                 open={open}
                                 setOpen={setOpen}
                                 mobileMarketingMenuOpen={mobileMarketingMenuOpen}
                                 currUser={currUser}
                            // currentCustomerId={currUser.customerId}
                            // currentUserRole={currUser.role}
                            // currentUserlastName={currUser.lastName}
                            // currentCustomerCompanyName={currUser.companyName}
                        />

                        <Box
                            // component="main"
                            sx={{
                                flexGrow: 1,
                                paddingTop: theme.spacing(3),
                                [theme.breakpoints.up('md')]: {
                                    transition: theme.transitions.create('margin', {
                                        easing: theme.transitions.easing.easeOut,
                                        duration: theme.transitions.duration.enteringScreen,
                                    }),
                                    marginLeft: !isAuthenticated ? 0 : isAuthenticated && open ? "240px" : isAuthenticated && "72px",
                                    marginRight: isMobile && showQPilotChat ? "75vw" : !isAuthenticated ? 0 : `${qPilotDrawerWidth}px`,
                                    // : isAuthenticated ? qPilotDrawerWidth : undefined,
                                    // marginRight: isMobile && showQPilotChat ? "75vw" : showQPilotChat ? qPilotDrawerWidth : isAuthenticated ?  : undefined,
                                }
                            }}
                        >

                            <Grid container justifyContent="center" spacing={0}>
                                <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                    xl
                                >
                                    {upcomingMaintenance && Object.keys(upcomingMaintenance).length > 0 && upcomingMaintenance['scheduled_maintenances']
                                        && upcomingMaintenance['scheduled_maintenances'].length > 0 &&
                                        parseJSON(upcomingMaintenance["scheduled_maintenances"][0]['scheduled_for']) > new Date() &&
                                        parseJSON(upcomingMaintenance["scheduled_maintenances"][0]['scheduled_until']) < new Date() &&
                                        <Grid item xs={12}>
                                            <Accordion elevation={1} square>
                                                <AccordionSummary>
                                                    {upcomingMaintenance && Object.keys(upcomingMaintenance)
                                                        && upcomingMaintenance['scheduled_maintenances'] &&
                                                        <React.Fragment><MdInfo color={"#579FEE"}
                                                                                size={18}/>&nbsp;&nbsp;<Typography
                                                            variant="h4">Scheduled maintenance in progress (click
                                                            for
                                                            details)</Typography>
                                                        </React.Fragment>
                                                    }
                                                </AccordionSummary>
                                                {upcomingMaintenance && Object.keys(upcomingMaintenance).length > 0 && upcomingMaintenance['scheduled_maintenances']
                                                    && upcomingMaintenance['scheduled_maintenances'].length > 0 &&
                                                    parseJSON(upcomingMaintenance["scheduled_maintenances"][0]['scheduled_for']) > new Date() &&
                                                    <AccordionDetails>
                                                        Maintenance scheduled
                                                        for {format(parseJSON(upcomingMaintenance["scheduled_maintenances"][0]['scheduled_for']), "MMM d, yyyy hh:mm aaaaa'm'")} - {format(parseJSON(upcomingMaintenance["scheduled_maintenances"][0]['scheduled_until']), "MMM d, yyyy hh:mm aaaaa'm'")}.
                                                        View status details and history on our <Link target="_blank"
                                                                                                     href={"https://status.qflow.ai"}>status
                                                        page</Link>.
                                                    </AccordionDetails>
                                                }
                                            </Accordion>
                                        </Grid>
                                    }

                                    <Grid item xs={12}>
                                        <Routes
                                        />


                                        {trialAlertOpen &&
                                            <TrialReminderSnackbar
                                                alertOpen={trialAlertOpen}
                                                handleTrialAlertClose={handleTrialAlertClose}
                                                // currentNow={currentNow}
                                            />
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                        {isAuthenticated &&
                            <QPilotChatbot
                                qPilotDrawerWidth={qPilotDrawerWidth}
                                isMobile={isMobile}
                                handleShowQPilotChatChange={handleShowQPilotChatChange}
                                showQPilotChat={showQPilotChat}
                                currUser={currUser}
                            />
                        }
                        {!isAuthenticated && !isAuthenticating &&
                            <UnauthenticatedFooter/>
                        }

                        {!isAuthenticated && !isAuthenticating &&
                            <CookieConsent
                                location="bottom"
                                buttonText="Accept Cookies"
                                cookieName="qflow_cookie_consent"
                                style={{
                                    background: "#FFF",
                                    color: "#272931",
                                    paddingLeft: "15%",
                                    justifyContent: "flex-start"
                                }}
                                enableDeclineButton
                                declineButtonStyle={{
                                    backgroundColor: "#fff",
                                    color: "#272931",
                                    border: "1px #272931 solid",
                                    fontSize: "13px",
                                    borderRadius: "4px"
                                }}
                                declineButtonText={"Do Not Sell My Personal Information"}
                                contentStyle={{maxWidth: 550}}
                                buttonStyle={{
                                    backgroundColor: "#272931",
                                    color: "#FFF",
                                    fontSize: "13px",
                                    borderRadius: "4px"
                                }}
                                expires={150}
                            >
                                This website uses cookies to enhance user experience and to analyze performance and
                                traffic.
                                {" "}
                            </CookieConsent>
                        }

                        {/*</DataContext.provider>*/}
                    </AppContext.Provider>
                    {/*</ErrorBoundary>*/}
                </Box>
            </Elements>
            :
            null
    )
}
export default withRouter(App);